import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { Main, AppHeader, AppCharacter, ButtonGroup, QuestionButton, TextArea, ConfirmButton } from './useStyles';
import '../App.css';
import ageGroupsData from './ageGroupsData';
import useQuestions from './Questions';
import usePredictiveText from './usePredictiveText';
import validateInput from './ValidateInput';
import useGeoData from './useGeoData';
import BackButton from './BackButton';
import QuestionsDisplay from './QuestionsDisplay';
import ResponseAnalyzing from './ResponseAnalyzing';
import ResponseNoResults from './ResponseNoResults';
import ResponseResults from './ResponseResults';

const REACT_APP_CLIENT = process.env.REACT_APP_CLIENT;
const ISABEL_API_URL = process.env.REACT_APP_ISABEL_API_URL;
const AUTH_TOKEN = process.env.REACT_APP_ISABEL_API_AUTH_TOKEN;

const Questionnaire = () => {
    const { t } = useTranslation();
    const { geoData, isLoading: isGeoLoading, error: geoError } = useGeoData();
    const [forSelf, setForSelf] = useState(true);
    const questions = useQuestions(forSelf);
    const predictiveText = usePredictiveText(); // Use the predictive text hook here
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [responses, setResponses] = useState({});
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [isInputValid, setIsInputValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [showNoResults, setShowNoResults] = useState(false);
    const [triageData, setTriageData] = useState({});
    const [apiResponses, setApiResponses] = useState({});
    const [dobGroup, setDobGroup] = useState(null);
    const [dobAnswers, setDobAnswers] = useState([]);
    const theme = useTheme();

    useEffect(() => {
        // Clear the PIN and email sent status on initial load or restart
        sessionStorage.removeItem('userPin');  // Remove any existing PIN
        sessionStorage.removeItem('emailSent'); // Remove any existing email sent status
    }, []);

    const handleInitialResponse = (response) => {
        setForSelf(response === 'self');
        setCurrentQuestionIndex(1);
    };

    const canConceiveBasedOnDOB = useCallback((dob) => {
        const selectedAgeGroup = ageGroupsData.age_groups.age_group.find(group => group.agegroup_id === dob);
        return selectedAgeGroup && selectedAgeGroup.can_conceive === "true";
    }, []);

    const allAnswersValid = useCallback(() => {
        return questions.every(question => validateInput(responses[question.id], question.inputType));
    }, [questions, responses]);

    const shouldSkipQuestion = useCallback((tempResponses, nextIndex) => {
        if (questions[nextIndex]?.id === 'pregnant') {
            const isMale = tempResponses['sex'] === 'm';
            const cannotConceive = !canConceiveBasedOnDOB(tempResponses['dob']);
            return isMale || cannotConceive;
        }
        return false;
    }, [questions, canConceiveBasedOnDOB]);

    const goToPreviousQuestion = useCallback(() => {
        if (currentQuestionIndex > 0) {
            let newIndex = currentQuestionIndex - 1;

            if (questions[newIndex].id === 'pregnant' && (responses['sex'] === 'm' || !canConceiveBasedOnDOB(responses['dob']))) {
                newIndex--;
            }

            setCurrentQuestionIndex(newIndex);
            setIsInputValid(allAnswersValid());
        }
    }, [currentQuestionIndex, questions, responses, allAnswersValid, canConceiveBasedOnDOB]);

    const proceedToNextQuestion = useCallback((tempResponses = responses) => {
        let nextIndex = currentQuestionIndex + 1;
        if (shouldSkipQuestion(tempResponses, nextIndex)) {
            nextIndex++;
        }

        if (nextIndex < questions.length) {
            setCurrentQuestionIndex(nextIndex);
            setIsInputValid(false);
        } else {
            setIsInputValid(allAnswersValid());
        }

        setResponses(tempResponses);
    }, [currentQuestionIndex, shouldSkipQuestion, responses, questions, allAnswersValid]);

    const handleResponse = (questionId, answerValue, inputType) => {
        const tempResponses = { ...responses, [questionId]: answerValue };
        const isValid = validateInput(answerValue, inputType);
        setResponses(tempResponses);

        if (questionId === 'dobGroup') {
            console.log("DOB Group selected:", answerValue);
            setDobGroup(answerValue); // Set the dobGroup state separately
            setCurrentQuestionIndex(currentQuestionIndex + 1); // Move to the DOB question after selecting the group
        } else if (isValid && inputType !== 'text' && currentQuestionIndex < questions.length - 1) {
            proceedToNextQuestion(tempResponses);
        } else {
            setIsInputValid(false);
        }

        if (currentQuestionIndex === questions.length - 1) {
            setIsInputValid(allAnswersValid());
        }
    };

    const populateDobAnswers = useCallback((group) => {
        console.log("Populating DOB Answers for group:", group);
        let answers = [];
        if (group === 'kids') {
            answers = ageGroupsData.age_groups.age_group.filter((group) => ['1', '2', '3', '10'].includes(group.agegroup_id)).map((group) => ({
                value: group.agegroup_id,
                text: `${group.name} (${group.yr_from} - ${group.yr_to})`,
            }));
        } else if (group === 'young_adult') {
            answers = ageGroupsData.age_groups.age_group.filter((group) => ['4', '7', '5', '8', '9'].includes(group.agegroup_id)).map((group) => ({
                value: group.agegroup_id,
                text: `${group.name} (${group.yr_from} - ${group.yr_to})`,
            }));
        } else if (group === 'elder') {
            answers = ageGroupsData.age_groups.age_group.filter((group) => group.agegroup_id === '6').map((group) => ({
                value: group.agegroup_id,
                text: `${group.name} (${group.yr_from} - ${group.yr_to})`,
            }));
            // Immediately proceed to next question for the "elder" group
            proceedToNextQuestion({ ...responses, dob: answers[0].value });
        }

        // Only update the state if the answers are different
        setDobAnswers((prevAnswers) => {
            if (JSON.stringify(prevAnswers) !== JSON.stringify(answers)) {
                return answers;
            }
            return prevAnswers;
        });
    }, [proceedToNextQuestion, responses]);

    useEffect(() => {
        if (dobGroup) {
            populateDobAnswers(dobGroup);
            setDobGroup(null); // Reset dobGroup to null after populating answers
        }
    }, [dobGroup, populateDobAnswers]);

    const renderQuestionInput = (question) => {
        let inputComponent = null;
        let isTextComplete = question.inputType === 'text' ? responses[question.id]?.trim() !== '' : true;

        let answers = question.id === 'dob' ? dobAnswers : question.answers;

        // Filter out 'kids' option if the first question's response is 'self'
        if (question.id === 'dobGroup' && forSelf) {
            answers = answers.filter(answer => answer.value !== 'kids');
        }

        switch (question.inputType) {
            case 'text':
                inputComponent = (
                    <div className="textarea-container">
                        <TextArea
                            rows={4}
                            value={responses[question.id] || ''}
                            id={question.id}
                            onChange={handleTextInputChange}
                            className={filteredSuggestions.length > 0 ? 'expanded' : ''}

                        />
                        {showSuggestions && (
                            <ul className="suggestions-list" style={{ position: 'absolute', zIndex: 1000 }}>
                                {filteredSuggestions.map((suggestion, index) => (
                                    <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                                        {suggestion}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                );
                break;
            case 'button':
                inputComponent = (
                    <ButtonGroup>
                        {answers.map((answer, index) => (
                            <QuestionButton
                                key={index}
                                variant={responses[question.id] === answer.value ? "contained" : "outlined"}
                                onClick={() => handleResponse(question.id, answer.value, question.inputType)}
                            >
                                {answer.text}
                            </QuestionButton>
                        ))}
                    </ButtonGroup>
                );
                break;
            default:
                inputComponent = null;
        }

        return (
            <>
                {inputComponent}
                {question.inputType === 'text' && currentQuestionIndex !== questions.length - 1 && (responses[question.id] !== undefined && isTextComplete) && (
                    <ConfirmButton variant="contained" onClick={() => proceedToNextQuestion()}>
                        {t('confirm-answer')}
                    </ConfirmButton>
                )}
            </>
        );
    };

    const handleTextInputChange = (e) => {
        const value = e.target.value;
        const questionId = e.target.id;

        setResponses({ ...responses, [questionId]: value });

        const lastWord = value.split(',').pop().trim();

        if (lastWord.length >= 3) {
            const filtered = predictiveText.filter(option => option.toLowerCase().startsWith(lastWord.toLowerCase())).slice(0, 10);
            setFilteredSuggestions(filtered);
            setShowSuggestions(true);
        } else {
            setShowSuggestions(false);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        const questionId = "querytext";
        const currentValue = responses[questionId] || '';
        const lines = currentValue.split(',');
        const lastLineIndex = lines.length - 1;
        lines[lastLineIndex] = suggestion;

        const newValue = lines.join(', ') + ', '; // Join lines and add a comma and space at the end
        setResponses({ ...responses, [questionId]: newValue });
        setShowSuggestions(false);
    };

    const handleSubmit = async () => {
        console.log('Submitting responses:', responses);
        if (isInputValid) {
            if (!geoData) {
                if (isGeoLoading) {
                    console.log('Geo data is still loading, please wait...');
                    return;
                }
                if (geoError) {
                    console.error('Error fetching geo data:', geoError);
                    return;
                }
                console.log('Geo data not available for submission');
                return;
            }
            setIsLoading(true);

            const formattedResponses = { ...responses };
            const queryParams = new URLSearchParams(formattedResponses);

            queryParams.append('language', process.env.REACT_APP_LANGUAGE);
            queryParams.append('specialties', '28');
            queryParams.append('region', geoData.region_id);
            queryParams.append('country_id', geoData.country_id);
            queryParams.append('suggest', 'Suggest+Differential+Diagnosis');
            queryParams.append('flag', 'sortbyRW_advanced');
            queryParams.append('searchType', 0);
            queryParams.append('web_service', 'json');

            const queryString = queryParams.toString();
            const apiEndpoint = `${ISABEL_API_URL}ranked_differential_diagnoses?${queryString}`;
            try {
                const initialResponse = await fetch(apiEndpoint, {
                    method: 'GET',
                    headers: {
                        'Authorization': AUTH_TOKEN
                    },
                });
                if (!initialResponse.ok) {
                    throw new Error('Network response was not ok');
                }
                const initialData = await initialResponse.json();
                console.log('Submission Successful', initialData || 'No data received');

                if (initialData.diagnoses_checklist.no_result) {
                    setIsLoading(false);
                    setShowNoResults(true);
                    setIsSubmitted(true);
                    setApiResponses(initialData);
                    return;
                } else {
                    setApiResponses(initialData);
                }
                let triageApiUrl = new URL(initialData.diagnoses_checklist.triage_api_url);
                let searchParams = new URLSearchParams(triageApiUrl.search);

                searchParams.set('Q1', responses.Q1 || '');
                searchParams.set('Q2', responses.Q2 || '');
                searchParams.set('Q3', responses.Q3 || '');
                searchParams.set('Q4', responses.Q4 || '');
                searchParams.set('Q5', responses.Q5 || '');
                searchParams.set('Q6', responses.Q6 || '');
                searchParams.set('Q7', responses.Q7 || '');

                triageApiUrl.search = searchParams.toString();

                const triageResponse = await fetch(triageApiUrl, {
                    method: 'GET',
                    headers: {
                        'Authorization': AUTH_TOKEN,
                    },
                });
                if (!triageResponse.ok) {
                    throw new Error('Network response was not ok for triage request');
                }
                const triageData = await triageResponse.json();
                console.log('Triage Request Successful', triageData);
                setTriageData(triageData);

                if (initialData.diagnoses_checklist?.diagnoses.length > 0 || !showNoResults) {
                    setIsSubmitted(true);
                }
            } catch (error) {
                console.error('Submission failed:', error);
            } finally {
                setIsLoading(false);
            }
        } else {
            console.log('Invalid input');
        }
    };

    const restartQuestionnaire = () => {
        setShowNoResults(false);
        setIsSubmitted(false);
        setCurrentQuestionIndex(0);
        setResponses({});
        setIsInputValid(false);
        setIsLoading(false);
    };
    const currentQuestion = questions[currentQuestionIndex];

    if (isLoading) {
        return <ResponseAnalyzing forSelf={forSelf} />;
    }

    return (
        <Main sx={{ backgroundColor: isSubmitted ? theme.palette.primary.main : (questions[currentQuestionIndex]?.backgroundColor || theme.palette.primary.main) }}>
            <AppHeader>
                <div style={{ minWidth: '50px' }}>
                    {currentQuestionIndex > 0 && !isSubmitted && (
                        <BackButton show={currentQuestionIndex > 0 && !isSubmitted} goBack={goToPreviousQuestion} t={t} color={'white'} />
                    )}
                </div>
                <a href='/' aria-label={t('home')}><img src='/logo.svg' alt={REACT_APP_CLIENT} /></a>
                <div style={{ minWidth: '50px' }}></div>
            </AppHeader>

            {!isSubmitted ? (
                <>
                    <AppCharacter>
                        <img
                            src={isSubmitted ? '/drita_9.png' : `/${questions[currentQuestionIndex]?.pic}`}
                            alt="Isabel"
                            style={{ maxHeight: '255px' }}
                        />
                    </AppCharacter>

                    {currentQuestion && (
                        <QuestionsDisplay
                            question={currentQuestion}
                            onRespond={handleInitialResponse}
                            renderInput={renderQuestionInput}
                            isLast={currentQuestionIndex === questions.length - 1}
                            isValid={isInputValid}
                            onSubmit={handleSubmit}
                            isLoading={isLoading}
                            isInitial={currentQuestionIndex === 0}
                            t={t}
                        />
                    )}
                </>
            ) : showNoResults ? (
                <ResponseNoResults apiResponses={apiResponses} onRestart={restartQuestionnaire} />
            ) : (
                <ResponseResults apiResponses={apiResponses} triageData={triageData} onRestart={restartQuestionnaire} />
            )}
        </Main>
    );
};

export default Questionnaire;
